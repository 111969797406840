.home .hero {
  background: linear-gradient(
      198deg,
      hsl(5deg 69% 59%) 0%,
      hsl(335deg 53% 53%) 13%,
      hsl(286deg 32% 47%) 25%,
      hsl(274deg 30% 49%) 37%,
      hsl(345deg 65% 63%) 50%,
      hsl(27deg 86% 64%) 63%,
      hsl(44deg 74% 61%) 75%,
      hsl(53deg 66% 61%) 87%,
      hsl(65deg 64% 64%) 90%,
      hsl(0deg 0% 100%) 100%
    ),
    linear-gradient(
      180deg,
      rgba(212, 222, 103, 1) 0%,
      rgba(255, 255, 255, 1) 76%
    );
  width: calc(100% + 40px);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -30px;
  padding-inline: 20px;
  background-image: url(./../../assets/images/fire-image.png),
    url(./../../assets/images/orange-box.png);
  background-repeat: no-repeat;
  background-position: bottom 0 left calc(50% - 540px),
    bottom 0 right calc(50% - 448px);
}

.hero .tex-container {
  max-width: 500px;
}

.hero .heading {
  color: var(--primary-color);
  font-size: 5.5rem;
  font-weight: 800;
  margin-bottom: 40px;
  line-height: 1;
}

.subtitle {
  color: #fff;
  font-weight: 300;
  line-height: 2;
  margin-bottom: 3rem;
}

.burn-section {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fff;
  flex-wrap: wrap;
  width: calc(100% + 40px);
  background-image: url(./../../assets/logo/brightswitch@3x.png);
  background-repeat: no-repeat;
  background-position: left -400px top 50%;
}

.burn-section > h3 {
  text-align: center;
  font-size: 4.5rem;
  margin-bottom: 30px;
  color: var(--dim-gray);
}

.featured-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
}

.burn-card {
  height: 100%;
  max-height: 584px;
  background-color: var(--blue-sapphire);
  color: #fff;
  max-width: 300px;
  border-radius: 20px;
  padding: 20px;
  background-image: url(http://localhost:7878/static/media/featured-switches.293d3df7c603a6f124d0.png);
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-position: bottom;
}

.featured-creators {
  display: flex;
  max-width: 90vw;
  --_spacer: 30px;
  gap: var(--_spacer);
  padding: 0 var(--_spacer) var(--_spacer);
  overflow-x: auto;
  overscroll-behavior-inline: contain;
  scroll-snap-type: inline mandatory;
  scroll-padding-inline: var(--_spacer, 1rem);
}

.featured-creators > * {
  scroll-snap-align: start;
}
.featured-creators img,
.featured-creators video {
  width: 300px;
  height: 300px;
  border-radius: 10px;
}

.artist-card {
  position: relative;
  cursor: pointer;
}

.artist-card .legend {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 20px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 30px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  color: #fff;
}
.burn-card > img {
  max-height: 260px;
}

.rewards {
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: center;
  gap: 10px;
  margin-bottom: 4rem;
  flex: 1;
}

.rewards svg {
  height: 60px;
  width: 60px;
}

.burn-btn {
  all: unset;
  background: #de5a4d 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  opacity: 1;
  font-weight: 700;
  padding: 10px 30px;
  cursor: pointer;
  color: #fff;
}

.realtime-activity .table {
  width: 100%;
  max-height: 400px;
  white-space: nowrap;
  overflow-x: auto;
  text-align: center;
  padding-bottom: 20px;
  /* overflow: auto; */
  /* position: relative; */
}

.realtime-activity {
  background-color: #f0f0f0;
  color: var(--dim-gray);
  width: calc(100% + 40px);
  display: grid;
}

.realtime-activity h3 {
  text-align: center;
  font-size: 4.5rem;
  margin-bottom: 30px;
}

.realtime-activity .table div {
  display: inline-block;
  margin-inline: 10px;
}

.realtime-activity .table p {
  white-space: nowrap;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.stats {
  background-color: var(--blue-sapphire);
  color: var(--dim-gray);
  width: calc(100% + 40px);
  display: grid;
  padding: 20px;
  justify-items: center;
}

.home-states {
  background-image: url(./../../assets/images/brightswitch-Logo-gradient.png),
    url(./../../assets/images/brightswitch-Logo-gradient.png);
  background-repeat: no-repeat;
  background-position: top -20% left -600px, top -20% right -700px;
}

.stats h3 {
  text-align: center;
  font-size: 4.5rem;
  margin-bottom: 30px;
  margin-top: 20px;
  color: var(--primary-color);
}

.stats .nft-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, 300px);
  width: 100%;
  justify-content: center;
  gap: 40px;
  max-height: 1000px;
  overflow-y: auto;
  padding-block: 10px;
}

.stats .card {
  cursor: pointer;
  display: grid;
  justify-items: center;
  align-content: space-between;
  gap: 20px;
  border-radius: 5px;
  padding: 15px;
  transition: 200ms linear;
}

.selected-card {
  box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.5), 0 0 0 2px #3057d5;
}

.stats .card img,
.stats .card video {
  width: 268px;
  height: 268px;
  border-radius: 20px;
  background-color: #009bf8;
}

.stats .card h4 {
  text-align: center;
  max-width: 230px;
  font-size: 2.8rem;
  color: var(--primary-color);
}

.stats .card div {
  background-color: var(--pale-spring-bud);
  padding: 15px 25px;
  border-radius: 15px;
  text-align: center;
}

.stats .card p {
  font-size: 1.6rem;
  font-weight: 600;
  color: var(--dim-gray);
}
