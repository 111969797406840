.main-layout {
  display: grid;
  justify-items: center;
  padding-inline: 20px;
  font-family: var(--font-mulish);
  position: relative;
}

.header {
  margin-bottom: 30px;
  background: #272727;
  /* width: calc(100% + 40px); */
  width: 100%;
  position: sticky;
  top: 0px;
  left: auto;
  right: 0px;
  z-index: 2;
}

.main-layout picture {
  margin-block: 20px;
}

.wallet-button .wallet-adapter-button-start-icon {
  display: none;
}

.wallet-button .wallet-adapter-button {
  padding: 0 30px !important;
  background-color: var(--cyan-blue) !important;
}

.header .profile {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  min-width: 100px;
}

.header .profile img {
  border-radius: 50%;
  height: 30px;
  width: 30px;
}

.header .profile .user-name {
  white-space: nowrap;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header picture {
  cursor: pointer;
}

@media (min-width: 600px) {
  .wallet-button {
    margin-top: 20px;
    position: absolute;
    right: 50px;
    top: -4px;
  }
}
