.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  /* display: inline-block;
  vertical-align: middle; */
  pointer-events: none;
  border: 0 solid transparent;
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  /* background: rgb(0 0 0 / 10%); */
}

.loader:before,
.loader:after {
  content: "";
  border: 2em solid currentcolor;
  border-radius: 50%;
  width: inherit;
  height: inherit;
  position: absolute;
  animation: loader 1s linear infinite;
  opacity: 0;
}

.loader:before {
  animation-delay: 1s;
  color: var(--pale-spring-bud);
}
.loader:after {
  animation-delay: 0.5s;
  color: rgb(51 53 111);
  /* color: var(--blue-sapphire); */
}

.app-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10rem;
  height: 10rem;
  transform: translate(-50%, -50%);
}

.app-loader:before,
.app-loader:after {
  top: 0;
  left: 0;
}

@keyframes loader {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
