.switch .switch-container {
  display: flex;
  align-items: start;
  justify-content: center;
  gap: 20px;
  margin-block: 20px;
  flex-wrap: wrap;
}

.switch .switch-btn {
  margin-inline: auto;
  width: 100%;
  max-width: 350px;
  margin-bottom: 10px;
}

.switch .available {
  background: #f9e1df;
  border-radius: 11px;
}

.switch .available .card {
  display: flex;
  gap: 40px;
  padding: 20px 30px;
}

.switch .available .card > p {
  writing-mode: vertical-lr;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 2rem;
  rotate: 180deg;
  text-align: center;
  color: #de5a4d;
  cursor: pointer;
  transition: all 0.5s;
}

.switch .available .card > p:hover {
  filter: brightness(110%);
}

.switch .available .card .details {
  display: flex;
  align-items: center;
  gap: 20px;
  flex: 1;
}

.switch .available .details .icons {
  display: grid;
  gap: 10px;
  justify-items: center;
  align-items: center;
  margin-left: auto;
}

.switch .available .details .icons svg {
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.switch .available.receive {
  background-color: rgb(212 222 103 / 90%);
}

.switch .available.receive .card > p {
  color: #abc948;
  margin-bottom: -5px;
}

.disable-btn-color {
  background-color: var(--silver) !important;
}

.switch > p {
  color: #222222;
  text-align: center;
  font-weight: 600;
  margin-bottom: 5px;
}
