.switch-detail .hero {
  width: calc(100% + 40px);
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin-top: -30px;
  background: var(--linear-background);
  border-bottom: var(--linear-background);
  position: relative;
}

.switch-detail .hero::after {
  content: "";
  position: absolute;
  bottom: -20px;
  left: -14px;
  width: 100%;
  height: 40px;
  filter: blur(8px);
  background: linear-gradient(
    155deg,
    hsl(274deg 30% 49%) 0%,
    hsl(345deg 65% 63%) 17%,
    hsl(27deg 86% 64%) 40%,
    hsl(44deg 74% 61%) 57%,
    hsl(53deg 66% 61%) 70%,
    hsl(65deg 64% 64%) 86%,
    hsl(0deg 0% 100%) 100%
  );
}

.switch-detail .hero > div:first-child {
  max-width: 600px;
  justify-self: flex-end;
  display: grid;
  justify-items: start;
  margin-left: 20px;
}

.switch-detail .hero > div:last-child {
  background: #272727 0% 0% no-repeat padding-box;
  border-top-left-radius: 32px;
  border-bottom-left-radius: 32px;
  padding: 40px;
}

.switch-detail .hero h2 {
  color: var(--primary-color);
  font-size: 4rem;
  font-weight: 800;
  margin-bottom: 20px;
}

.switch-detail .hero p {
  color: #fff;
}

.switch-detail .hero img,
.switch-detail .hero video {
  height: 250px;
  width: 250px;
  margin-bottom: 20px;
}

.switch-detail .hero img + h2 {
  color: var(--pale-spring-bud);
  margin-bottom: 10px;
  font-size: 2.4rem;
}

.switch-detail .hero img + h2 + p {
  color: var(--primary-color);
}

.switches {
  background: #fff;
  width: calc(100% + 40px);
  display: grid;
  justify-content: center;
  padding-inline: 20px;
  gap: 10px;
}

.switches .heading {
  font-size: 3rem;
  font-weight: bold;
  color: var(--primary-dark-color);
  text-align: center;
}

.switches > div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4rem;
  flex-wrap: wrap;
  max-width: 1500px;
}

.line {
  border: 1px solid #707070;
  text-align: center;
  margin: 50px;
}

.nfts {
  background-color: var(--primary-dark-color);
  color: var(--dim-gray);
  display: grid;
}

.nfts h3 {
  text-align: center;
  font-size: 4.5rem;
  margin-block: 10px;
  color: var(--primary-color);
}

.nfts .nft-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
  max-height: 50rem;
  overflow-y: auto;
  padding-block: 10px;
  margin-bottom: 20px;
}

.nfts .nft-container > span {
  font-size: 20px;
  font-family: sans-serif;
  margin-bottom: 20px;
}

.nfts .card {
  cursor: pointer;
  display: grid;
  justify-items: center;
  gap: 20px;
  border-radius: 5px;
  padding: 15px;
  transition: 200ms linear;
  box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.5), 0 0 0 2px #fff;
}

.nfts .selected-card {
  box-shadow: 0 2px 4px 0 rgb(255 252 252 / 50%), 0 0 0 2px rgb(20 76 255);
}

.nfts .card img {
  width: 168px;
  height: 168px;
}

.nfts .card h4 {
  font-size: 2.8rem;
  color: var(--primary-color);
}

.nfts .card div {
  background-color: var(--pale-spring-bud);
  padding: 15px 25px;
  border-radius: 15px;
  text-align: center;
}

.nfts .card p {
  font-size: 1.6rem;
  font-weight: 600;
  color: var(--dim-gray);
}

.switch-button {
  margin-bottom: 20px !important;
  margin-inline: 20px !important;
  width: auto;
}

.not-available {
  text-align: center;
  margin-bottom: 20px;
}

.batch-modal .ant-modal-body {
  font-family: var(--font-mulish);
  color: var(--dim-gray);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-direction: column;
  padding: 2rem;
  text-align: center;
}

.batch-modal > svg,
.batch-modal p + svg {
  height: 70px;
  width: 70px;
  color: var(--amber);
}

.batch-modal h1 {
  font-size: 3.2rem;
}

.batch-modal .success {
  display: flex;
  gap: 10px;
  align-items: center;
}

.batch-modal .success > img {
  height: 200px;
  width: 200px;
}

.batch-modal .success h1 {
  color: var(--pale-spring-bud);
}

.batch-modal .failed {
  color: var(--error-color);
}

.batch-modal .ant-modal-body > button {
  all: unset;
  padding: 1rem 2rem;
  color: var(--dim-gray);
  border: 1px solid var(--dim-gray);
  border-radius: 5px;
  font-size: 2rem;
  font-weight: 600;
  cursor: pointer;
  box-shadow: 1px 2px 5px 0px rgb(0 0 0 / 30%);
}

.batch-modal a {
  color: var(--blue-sapphire);
}

.batch-modal a:visited {
  color: var(--blue-sapphire);
}

.batch-modal .subtitle {
  font-weight: 600;
  color: var(--dim-gray);
  font-size: 12px;
}

.batch-modal .nft-name {
  max-width: 300px;
}

.batch-modal > svg + h1 {
  color: var(--amber);
}

.progress {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
  border-radius: 10px;
  overflow: hidden;
  flex-wrap: wrap;
}

.progress div {
  height: 10px;
  flex: 1;
  background-color: var(--silver);
}
