@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");

:root {
  --font-mulish: "Mulish", sans-serif;
  --primary-color: white;
  --primary-dark-color: #272727;
  --border-p-color: gray;

  --blue-sapphire: #5457a1;
  --amber: #e9b24d;
  --pale-spring-bud: #d4de67;
  --dim-gray: #646464;
  --cyan-blue: #59cbea;
  --silver: #848484;
  --error-color: #ff4d4f;
  --linear-background: linear-gradient(
      155deg,
      hsl(5deg 69% 59%) 0%,
      hsl(335deg 53% 53%) 13%,
      hsl(286deg 32% 47%) 25%,
      hsl(274deg 30% 49%) 37%,
      hsl(345deg 65% 63%) 50%,
      hsl(27deg 86% 64%) 63%,
      hsl(44deg 74% 61%) 75%,
      hsl(53deg 66% 61%) 87%,
      hsl(65deg 64% 64%) 90%,
      hsl(0deg 0% 100%) 100%
    ),
    linear-gradient(
      180deg,
      rgba(212, 222, 103, 1) 0%,
      rgba(255, 255, 255, 1) 76%
    );
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  scroll-behavior: smooth;
  scroll-padding-top: 5rem;
}

body {
  min-height: 100vh;
  font-size: 1.6rem;
  width: 100% !important;
  background: var(--linear-background);
}

body:has(
    .page-enter,
    .page-enter-active,
    .page-exit,
    .page-exit-active,
    .ant-modal-mask
  ) {
  overflow: hidden !important;
}

/* !media queries */
@media (max-width: 600px) {
  .switches .available .card {
    gap: 10px;
    padding: 20px 10px;
  }

  .header {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
    gap: 10px;
    padding: 10px;
  }
}

@media (max-width: 700px) {
  .main-layout .hero {
    grid-template-columns: 1fr;
  }

  .switch-detail .hero img,
  .switch-detail .hero video {
    height: 150px;
    width: 150px;
  }

  .switch-detail .hero > div:first-child {
    justify-items: center;
    justify-self: center;
    max-width: unset;
    margin-right: 20px;
  }
  .switch-detail .hero > div:first-child p {
    text-align: center;
  }
  .switch-detail .hero > div:first-child a {
    display: none;
  }

  .switch-detail .hero > div:last-child {
    background: transparent;
    margin-right: unset;
  }

  .switch-detail .hero > div:last-child div {
    width: auto !important;
  }

  .switch-detail .hero img + h2 {
    display: none;
  }

  .batch-modal h1 {
    font-size: 2.2rem;
  }
}

@media (max-width: 1015px) {
  .main-layout .hero {
    flex-direction: column;
    padding-top: 5rem;
    background-position: top 4% center, bottom -52% center;
  }

  .main-layout .hero > img {
    width: 100%;
    max-width: 500px;
    margin-top: 30px;
    margin-inline: 20px;
  }
  .main-layout .heading {
    font-size: 4rem;
  }
}
