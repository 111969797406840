.Profile > h1 {
  color: var(--primary-color);
  font-size: 3.4rem;
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
}

.Profile .card {
  box-shadow: 0px 20px 61px #413e6524;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  gap: 15px;
  padding: 20px 30px;
  max-width: 400px;
  width: 100%;
  transition: all 0.5s;
  background: rgb(89 203 234 / 40%);
  max-height: 80dvh;
  overflow-y: auto;
}

.Profile .card input {
  background: #fff !important;
}

.disable-card {
  pointer-events: none !important;
  filter: brightness(0.7) !important;
}

.Profile .card h2 {
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: -20px;
}

.Profile .card h1 {
  text-shadow: rgb(89 203 234 / 50%) -6px 3px;
  color: var(--cyan-blue);
  font-size: 5rem;
  letter-spacing: 1rem;
  text-align: center;
  font-weight: 500;
}

.Profile > h1 + .card {
  padding: 0;
  background: transparent;
  margin-bottom: 10px;
}

.Profile .sign-btn {
  padding-block: 5px;
  background: var(--amber);
}

.Profile .card input[placeholder] {
  all: unset;
  border: 1px solid #eae9f2;
  border-radius: 10px;
  padding: 10px;
}

.Profile .card input[placeholder]::placeholder {
  color: #9c9aaa;
}

.input-title {
  color: #9c9aaa;
  margin-bottom: -12px;
  margin-left: 4px;
}

.select-title span,
.input-title span {
  color: red;
  font-size: 1.7rem;
  margin-left: -3px;
}

.select-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.select-container.condensed {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.select-container select {
  padding: 10px 10px;
  border-radius: 10px;
  background: #fff;
  width: 100%;
}
.select-container.condensed select {
  width: 150px;
}

.select-title {
  color: #9c9aaa;
}

.Profile .card input[placeholder]:focus {
  outline: 1px solid var(--border-p-color);
}

.card .social {
  background-color: #9c9aaa;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px 20px;
  background: #54a6e6;
  /* border: 1px solid #eae9f2; */
  border-radius: 10px;
  cursor: pointer;
}

.card .social.discord {
  background: #7785d0;
}

.card .social:hover {
  filter: brightness(110%);
}

.card .social svg {
  height: 30px;
  width: 30px;
}

.card .social svg + span {
  color: var(--primary-color);
  font-size: 1.6rem;
  font-weight: 500;
}

.card .checkbox {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-self: start;
}

.card .checkbox p,
.signInRoute {
  font-size: 1.4rem;
  text-align: left;
}

.card .checkbox p span,
.signInRoute span {
  color: #5454d4;
  cursor: pointer;
}
.signInRoute {
  font-size: 1.3rem;
}

.user-avatar {
  text-align: center;
}

.user-avatar span {
  position: relative;
}

.user-avatar .user-image {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 2px solid var(--border-p-color);
}

.user-avatar .edit-icon {
  position: absolute;
  cursor: pointer;
  height: 25px;
  width: 25px;
  right: 0px;
}

.card .signInRoute {
  text-align: center;
}

.req-error {
  color: var(--error-color);
  font-size: 1.3rem;
  margin-left: 3px;
  margin-block: -15px;
  transition: all 0.2s;
  opacity: 0;
}

.req-error-show {
  opacity: 1;
}

.req-error-show:focus {
  outline-color: var(--error-color) !important;
}
