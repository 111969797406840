/* !random classes */
.c-p {
  cursor: pointer;
}

.w-100 {
  width: 100%;
}

.t-c {
  text-align: center;
}

.blue-Sapphire {
  background: var(--blue-sapphire);
}

.error-color {
  background-color: var(--error-color) !important;
}

.done-color {
  background-color: var(--pale-spring-bud) !important;
}

.continue-color {
  background-color: var(--amber) !important;
}

.dark-primary-back {
  background-color: var(--primary-dark-color);
}

.silver {
  background: var(--silver);
}

.y-c {
  color: yellow;
}

.transition {
  transition: all 0.5s;
}

.disable {
  pointer-events: none !important;
}

.flex-center {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.u-l {
  color: blue;
  text-decoration: underline;
}

.l-t {
  text-decoration: line-through;
  margin-inline: 5px;
  text-decoration-color: red;
  text-decoration-thickness: 3px;
}

/* !primary button */
.btn-primary {
  all: unset;
  background: #de5a4d;
  padding: 15px 20px;
  font-weight: 700;
  text-align: center;
  color: var(--primary-color);
  font-size: 2rem;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.5s;
  box-sizing: border-box;
  display: block;
  font-family: var(--font-mulish);
}

.btn-primary:hover {
  filter: brightness(110%);
}

/* !scroll bar */
.scroll-bar::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.scroll-bar::-webkit-scrollbar-thumb {
  background-color: rgb(84, 87, 160, 0.9);
  border-radius: 6px;
}

.scroll-bar::-webkit-scrollbar-thumb:hover {
  background-color: rgb(84, 87, 160, 1);
}

.scroll-bar::-webkit-scrollbar-track {
  background-color: rgb(0, 0, 0, 0.1);
}

.scroll-bar::-webkit-scrollbar-track:hover {
  background-color: rgb(0, 0, 0, 0.2);
}

/* !routes transition styles */
.page-enter {
  opacity: 0;
  transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 250ms, transform 250ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 250ms, transform 250ms;
}

.padding-block {
  padding-block: 6rem;
}
