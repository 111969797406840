.nft-name {
  font-size: 2rem;
  font-weight: 800;
  color: var(--primary-dark-color);
  max-width: 150px;
}

.nft-metadata-name {
  max-width: 150px;
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--silver);
}

.nft-image {
  height: 80px;
  width: 80px;
  position: relative;
}

.nft-image img,
.nft-image video {
  background-color: #009bf8;
  height: 100%;
  width: 100%;
}

.nft-image > span {
  position: absolute;
  background-color: var(--primary-dark-color);
  color: var(--primary-color);
  border-radius: 10px;
  font-size: 1.3rem;
  font-weight: 600;
  left: -13px;
  padding: 2px;
  top: -5px;
  min-width: 20px;
  text-align: center;
  z-index: 1;
}
