.modal__content .ant-modal-close-x svg {
  color: #fff;
}

.modal-close-icon-black .ant-modal-close-x svg {
  color: black;
}

.modal__content .ant-modal-content {
  padding: 0;
  border-radius: 10px;
  overflow: hidden;
}
.modal__content .ant-modal-footer {
  margin: 0;
}
